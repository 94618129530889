

















































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import {
  CHECK_GOAL_NAME,
  CREATE_GOAL,
  GET_APP_EVENTS_LIST,
  GET_GOALS,
  UPDATE_GOAL,
} from 'src/utils/apollo-queries';
import DeleteGoal from '../components/goals/DeleteGoal.vue';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '@core/services';
import { GoalTypes } from '@shared/enums';
import { updateCLF } from '../utils/util';
import { CheckListFields } from '@shared/enums/shared.enums'
import { isReadOnly} from '../utils/util';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import * as moment from 'moment';

export default Vue.extend({
  name: 'Goals',
  components: {
    DeleteGoal,
    PermissionReadOnlyPopup
  },
  data() {
    return {
      permissionReadOnlyMetadata:{},
      dialog: true,
      syncStatus: false,
      locale: 'en',
      loadingApps: false,
      searchedApps: [],
      selectedApp: '',
      searchApp: {
        get() {
          return {
            adamId: 0,
            appName: this.$t('overview.all'),
          };
        },
        set(e) {
          this.searchApp.adamId = e.adamId;
          this.searchApp.appName = e.appName;
        },
      },
      search: '',
      goalDialog: false,
      saving: false,
      selectedApp: null,
      appList: [],
      selectedGoalType: null,
      // goalTypes: [
      //   { header: this.$t('goals.revenue'), divider: true },
      //   { text: this.$t('goals.purchase'), value: GoalTypes.Purchase },
      //   { text: this.$t('goals.subscribe'), value: GoalTypes.Subscribe },

      //   { header: this.$t('goals.userEngagement'), divider: true },
      //   { text: this.$t('goals.levelComplete'), value: GoalTypes.UELevelComplete },
      //   { text: this.$t('goals.regComp'), value: GoalTypes.RegistrationComplete },
      //   { text: this.$t('goals.tutComp'), value: GoalTypes.TutorialComplete },
      //   { text: this.$t('goals.startTrial'), value: GoalTypes.StartTrial },
      //   { text: this.$t('goals.appLaunch'), value: GoalTypes.AppLaunch },

      //   { header: this.$t('goals.userAction'), divider: true },
      //   { text: this.$t('goals.levelComplete'), value: GoalTypes.UALevelComplete },
      //   { text: this.$t('goals.addToCart'), value: GoalTypes.AddtoCart },
      //   { text: this.$t('goals.addToWishlist'), value: GoalTypes.AddtoWishlist },
      //   { text: this.$t('goals.checkoutStart'), value: GoalTypes.CheckoutStart },

      //   // { text: "Achievement", value: GoalTypes.Achievement },
      //   // { text: "Ad Click", value: GoalTypes.AdClick },
      //   // { text: "Ad View", value: GoalTypes.AdView },
      //   // { text: "Rating", value: GoalTypes.Rating },
      //   // { text: "Search", value: GoalTypes.Search },
      //   // { text: "View", value: GoalTypes.View },
      //   // { text: "QSS", value: GoalTypes.QSS },
      //   // { text: "First Video View", value: GoalTypes.FirstVideoView },
      //   // { text: "Share With friend", value: GoalTypes.ShareWithFriend },
      //   // { text: "Authentication Success", value: GoalTypes.AuthenticationSuccess },
      //   // { text: "Watch Later", value: GoalTypes.WatchLater }
      // ],
      getGoalsWithApp: [],
      getGoalsWithAppFiltered: [],
      selectedEvent: null,
      daysSinceInstall: 'ALL',
      eventTypes: [],
      goalName: null,
      updateGoalName: null,
      deleteGoalMetadata: null,
      goalForm: false,
      updateGoalForm: false,
      updateGoalId: false,
    };
  },
  computed: {
    daysSinceInstallTypes: {
      cache: false,
      get() {
        return [
          { text: 'All Events Regardless of Time', value: 'ALL' },
          { text: 'Within 1 Day of Install', value: '1' },
          { text: 'Within 2 Days of Install', value: '2' },
          { text: 'Within 3 Days of Install', value: '3' },
          { text: 'Within 7 Days of Install', value: '7' },
          { text: 'Within 14 Days of Install', value: '14' },
          { text: 'Within 28 Days of Install', value: '28' }
        ]
      }
    },
    goalTypes: {
      cache: false,
      get() {
        return [
          { header: this.$t('goals.revenue'), divider: true },
          { text: this.$t('goals.purchase'), value: GoalTypes.Purchase },
          { text: this.$t('goals.subscribe'), value: GoalTypes.Subscribe },

          { header: this.$t('goals.userEngagement'), divider: true },
          {
            text: this.$t('goals.levelComplete'),
            value: GoalTypes.UELevelComplete,
          },
          {
            text: this.$t('goals.regComp'),
            value: GoalTypes.RegistrationComplete,
          },
          { text: this.$t('goals.tutComp'), value: GoalTypes.TutorialComplete },
          { text: this.$t('goals.startTrial'), value: GoalTypes.StartTrial },
          { text: this.$t('goals.appLaunch'), value: GoalTypes.AppLaunch },

          { header: this.$t('goals.userAction'), divider: true },
          {
            text: this.$t('goals.levelComplete'),
            value: GoalTypes.UALevelComplete,
          },
          { text: this.$t('goals.addToCart'), value: GoalTypes.AddtoCart },
          {
            text: this.$t('goals.addToWishlist'),
            value: GoalTypes.AddtoWishlist,
          },
          {
            text: this.$t('goals.checkoutStart'),
            value: GoalTypes.CheckoutStart,
          },

          // { text: "Achievement", value: GoalTypes.Achievement },
          // { text: "Ad Click", value: GoalTypes.AdClick },
          // { text: "Ad View", value: GoalTypes.AdView },
          // { text: "Rating", value: GoalTypes.Rating },
          // { text: "Search", value: GoalTypes.Search },
          // { text: "View", value: GoalTypes.View },
          // { text: "QSS", value: GoalTypes.QSS },
          // { text: "First Video View", value: GoalTypes.FirstVideoView },
          // { text: "Share With friend", value: GoalTypes.ShareWithFriend },
          // { text: "Authentication Success", value: GoalTypes.AuthenticationSuccess },
          // { text: "Watch Later", value: GoalTypes.WatchLater }
        ];
      },
    },

    goalsHeaders: {
      cache: false,
      get() {
        return [
          // {
          //   text: 'S.No',
          //   align: 'start',
          //   value: 'sno',
          // },
          {
            text: this.$t('goals.name'),
            align: 'start',
            value: 'name',
          },
          {
            text: this.$t('goals.app'),
            value: 'appDetails',
          },
          {
            text: this.$t('goals.appId'),
            value: 'appId',
          },
          // {
          //   text: this.$t('goals.type'),
          //   value: 'type',
          // },
          {
            text: this.$t('goals.events'),
            value: 'eventId',
          },
          {
            text: this.$t('goals.createdOn'),
            value: 'datetime',
          },
          {
            text: this.$t('goals.createdBy'),
            value: 'createdBy',
          },
          // {
          //   text: "Status",
          //   value: 'status',
          // },
          {
            text: this.$t('goals.action'),
            value: 'action',
            sortable: false,
          },
        ];
      },
    },

    appRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('createCampaignRules.selectApp')];
      },
    },

    goalTypeRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('goals.selectGoalRule')];
      },
    },

    eventTypeRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('goals.selectEventRule')];
      },
    },

    goalNameRules: {
      get(): string {
        return [(v) => !!v || this.$t('goals.goalNameRule'),
       
        ];
      },
    },
  },

  watch: {
    selectedApp(value) {
      if (value !== null) {
        this.appEventNames();
      }
    },
    
    goalName(value) {
      
       if (/[<>;\\[\]{}&%]/g.test(value)) {
         return this.goalNameRules.push(this.$t('createCampaignRules.specialCharNotAllowed'));
        
      }
       if (/\s\s/.test(value)) {
         return this.goalNameRules.push(this.$t('createCampaignRules.onlySinglespaceAllowed'));
        
      }
      if (value !== null) {
        const data = this.getGoalsWithApp?.filter(
          (item) => item.name.toLowerCase().trim() == value.toLowerCase().trim()
        );

        if (data[0]?.name == this.updateGoalName) {
          return this.goalNameRules.splice(1);
        }
        if (data.length != 0) {
          this.goalNameRules.push('Goal name already exists!');
        } else {
          this.goalNameRules.splice(1);
        }
      }
    },
  },

  apollo: {
    getGoals: {
      query: GET_GOALS,
      update(data) {
        this.getGoalsWithApp = [];
        this.getGoalsWithAppFiltered = [];
        for (let index = 0; index < data.getGoals.length; index++) {
          const element = data.getGoals[index];
          this.appListing(element, index);
        }
      },
    },
  },

  methods: {
   
    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
    populateApps() {
      this.searchedApps = LSS.ownApps; // takes no extra memory since they bot point to same object(array)
      // this.searchedApps = [...LSS.ownApps] //takes less memory
      // this.searchedApps = _.cloneDeep(LSS.ownApps) //takes more memory

      const attrExistAll = this.searchedApps.filter((e) => e.attrExist);
      this.searchedApps.unshift({
        adamId: 0,
        appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
        appIcon: 'mdi-cellphone',
        attrExist:
          this.searchedApps.length == attrExistAll.length ? true : false,
      });
      this.searchApp = {
        adamId: 0,
        appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
        attrExist:
          this.searchedApps.length == attrExistAll.length ? true : false,
        appIcon: '',
      };
      this.loadingApps = false;
    },

 popupGuided(){

   return this.$router.push('/campaign-generator');
 },






    appListing(item, i) {
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        const filteredApps = LSS.ownApps.filter((e) => e.attrExist);
        for (let index = 0; index < filteredApps.length; index++) {
          const element = filteredApps[index];
          if (element.adamId == item.appId) {
            this.getGoalsWithApp.push({
              appDetails: element,
              ...item,
            });
            this.getGoalsWithAppFiltered.push({
              appDetails: element,
              ...item,
            });
          }
        }
      }
    },
    changeApp() {
      if (this.searchApp.adamId == 0) {
        this.getGoalsWithAppFiltered = this.getGoalsWithApp;
      } else {
        this.getGoalsWithAppFiltered = this.getGoalsWithApp.filter(
          (e) => e.appId == this.searchApp.adamId
        );
      }
    },
    getAttrApps() {
      // this.appList.push({
      //   header: 'Attributed Apps',
      //   divider: true,
      // });
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        const filteredApps = LSS.ownApps.filter((e) => e.attrExist);
        for (let index = 0; index < filteredApps.length; index++) {
          const element = filteredApps[index];
          this.appList.push({
            appName: element.appName,
            adamId: element.adamId,
            appIcon: element.appIcon,
          });
        }
      }
    },

    async appEventNames() {
      await this.$apollo
        .query({
          query: GET_APP_EVENTS_LIST,
          variables: {
            appId: this.selectedApp,
          },
        })
        .then((res) => {
          this.eventTypes = JSON.parse(res.data.getAppEventsList.data);
        })
        .catch((error) => {
          this.$notify.error('Error while fetching app events');
        });
    },

    openCreateDialog() {
      this.getAttrApps();
      this.goalDialog = true;
    },

    async checkGoalName() {
      
      const result = await this.$apollo.query({
        query: CHECK_GOAL_NAME,
        variables: {
          name: this.goalName,
        },
      });

      if (result.data.checkGoalName.doesGoalExist) {
        this.goalNameRules.push('Goal name already exists!');
      } else {
        this.goalNameRules.splice(1);
      }

      // making validation rules run manually
      const val = this.goalName;
      this.goalName = '';
      setTimeout(() => {
        this.goalName = val;
      }, 0);
    },

    async createGoal() {
      this.$refs.goalForm.validate();
      this.saving = true;
      const inputs = {
        appId: this.selectedApp,
        name: this.goalName,
        type: GoalTypes.Purchase,
        eventId: this.selectedEvent,
        daysSinceInstall: this.daysSinceInstall,
        createdBy: decodeToken(LSS.token).FirstName,
      };
      await this.$apollo
        .query({
          query: CREATE_GOAL,
          variables: {
            inputs: inputs,
          },
        })
        .then((res) => {
          this.saving = false;
          this.closeGoalDialog();
          this.onUpdate();
          if (res.data.createGoal.code == 0) {
            this.$notify.success('Goal Created');
            updateCLF(CheckListFields['createMmpGoals'], this);
          } else if (res.data.createGoal.code == 7 || res.data.createGoal.code == 9) {
            this.$notify.error('Error while creating goal');
          } else if (res.data.createGoal.code == 5) {
            this.$notify.error('Error while activating events');
          }
        })
        .catch((error) => {
          this.saving = false;
          this.$notify.error('Something went wrong');
        });
    },
    openUpdateGoal(data) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.updateGoalForm = true;
      this.getAttrApps();
      this.goalDialog = true;
      this.selectedApp = data.appId;
      this.goalName = data.name;
      this.updateGoalName = data.name;
      this.selectedGoalType = GoalTypes.Purchase;
      this.daysSinceInstall = data.daysSinceInstall ? data.daysSinceInstall : 'ALL',
      this.selectedEvent = data.eventId;
      this.updateGoalId = data._id;
    },

    async updateGoal() {
      const events = Array.from(this.selectedEvent);

      if (events.length == 0) this.selectedEvent = null;
      else this.selectedEvent = events;

      this.$refs.goalForm.validate();
      if (this.selectedEvent == null) return;

      this.saving = true;
      const inputs = {
        appId: this.selectedApp,
        name: this.goalName,
        type: GoalTypes.Purchase,
        eventId: this.selectedEvent,
        daysSinceInstall: this.daysSinceInstall,
        createdBy: decodeToken(LSS.token).FirstName,
      };
      await this.$apollo
        .query({
          query: UPDATE_GOAL,
          variables: {
            goalId: this.updateGoalId,
            inputs: inputs,
          },
        })
        .then((res) => {
          this.saving = false;
          this.closeGoalDialog();
          this.onUpdate();
          if (res.data.updateGoal.code == 0) {
            this.$notify.success('Goal Updated');
          } else if (res.data.updateGoal.code == 7 || res.data.updateGoal.code == 9) {
            this.$notify.error('Error while updating goal');
          } else if (res.data.createGoal.code == 5) {
            this.$notify.error('Error while activating events');
          }
        })
        .catch((error) => {
          this.saving = false;
          this.$notify.error('Something went wrong');
        });
    },

    openDeleteGoal(data) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.deleteGoalMetadata = {
        goalId: data._id,
        now: Date.now(),
      };
    },

    onUpdate() {
      this.$apollo.queries.getGoals.refetch();
    },

    closeGoalDialog() {
      this.goalDialog = false;
      this.selectedApp = null;
     // this.selectedGoalType = null;
      this.selectedEvent = null;
      this.goalName = null;
      this.updateGoalForm = false;
      this.updateGoalName = null;
      this.daysSinceInstall = 'ALL';
      this.$refs.goalForm.resetValidation();
    },
  },
  mounted() {
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    this.locale = localStorage.getItem('locale') || 'en';
    this.populateApps();

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });
  },
});
