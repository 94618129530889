var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            { staticClass: "inner-container only-heading-part" },
            [
              _c(
                "div",
                {
                  staticClass: "top-page-header page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c("div", {
                    staticClass: "head-title top-space",
                    class: _vm.syncStatus ? "syncActive" : "",
                    attrs: { lang: _vm.locale },
                  }),
                ]
              ),
              _c(
                "v-container",
                {
                  staticClass: "v-main-content only-simple-content",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg extra-padding",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { id: "app" } },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "px-0 pt-0 pb-0" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "sub-heading-with-btn d-flex flex-wrap align-baseline mb-0",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "black-text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("goals.manageGoals")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "max-width": "300px",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "greyxdark--text",
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-information-outline"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("goals.goalDesc"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-baseline goal-header ",
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "max-275 mr-2",
                                          attrs: {
                                            "append-icon": "mdi-magnify",
                                            label: _vm.$t("labels.search"),
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.search,
                                            callback: function ($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search",
                                          },
                                        }),
                                        _c("v-autocomplete", {
                                          staticClass:
                                            "rounded-lg max-275 mr-2",
                                          attrs: {
                                            items: _vm.searchedApps,
                                            loading: _vm.loadingApps,
                                            "item-text": "appName",
                                            label: "Search App",
                                            "item-value": "adamId",
                                            required: "",
                                            outlined: "",
                                            dense: "",
                                            "prepend-inner-icon":
                                              "mdi-cellphone",
                                            "return-object": "",
                                            disabled:
                                              _vm.searchedApps.length < 2,
                                          },
                                          on: { change: _vm.changeApp },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  [
                                                    _c("v-img", {
                                                      staticClass: "rounded-lg",
                                                      attrs: {
                                                        color: "secondary",
                                                        "lazy-src":
                                                          data.item.appIcon,
                                                        "max-width": "30",
                                                        "max-height": "30",
                                                        "min-width": "20",
                                                        "min-height": "20",
                                                        src: data.item.appIcon,
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item-content",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.item
                                                                    .appName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.searchApp,
                                            callback: function ($$v) {
                                              _vm.searchApp = $$v
                                            },
                                            expression: "searchApp",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: "primary ",
                                                depressed: "",
                                              },
                                            },
                                            !_vm.isReadOnly()
                                              ? {
                                                  click: function () {
                                                    return _vm.openCreateDialog()
                                                  },
                                                }
                                              : {
                                                  click: function () {
                                                    return _vm.openPermissionReadOnlyPopup()
                                                  },
                                                }
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("goals.createGoal")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("v-data-table", {
                              staticClass:
                                "elevation-0 pa-0 mt-4 table-style-3",
                              attrs: {
                                "items-per-page": 10,
                                headers: _vm.goalsHeaders,
                                items: _vm.getGoalsWithAppFiltered || [],
                                search: _vm.search,
                                "no-data-text": _vm.$t("kai.showDataText"),
                                loading: _vm.$apollo.queries.getGoals.loading,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.eventId",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.eventId.length) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.action",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "no-m-no-p",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "font-22 icon-hover",
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openUpdateGoal(
                                                                            item
                                                                          )
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " mdi-pencil-outline "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [_c("span", [_vm._v("Edit")])]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "font-22 icon-hover",
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openDeleteGoal(
                                                                            item
                                                                          )
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " mdi-delete-outline "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Delete"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.sno",
                                    fn: function (ref) {
                                      var index = ref.index
                                      return [
                                        _vm._v(" " + _vm._s(index + 1) + " "),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.appDetails",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa-0 pr-0 d-flex align-center my-2 rounded-lg w-fit-content card",
                                            attrs: {
                                              color: "grey lighten-4",
                                              elevation: "0",
                                            },
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass:
                                                "rounded-lg image-sm",
                                              attrs: {
                                                color: "secondary",
                                                "lazy-src":
                                                  item.appDetails.appIcon,
                                                "max-width": "30",
                                                "max-height": "30",
                                                "min-width": "20",
                                                "min-height": "20",
                                                src:
                                                  item.appDetails.appIcon ||
                                                  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/240px-No_image_available.svg.png",
                                              },
                                            }),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-card-subtitle",
                                                  {
                                                    staticClass:
                                                      "pa-0 ma-0 body-2 body-sm-2 whitespace-nowrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.appDetails.appName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600px", persistent: "" },
                  model: {
                    value: _vm.goalDialog,
                    callback: function ($$v) {
                      _vm.goalDialog = $$v
                    },
                    expression: "goalDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "v-modal-fixed style-2" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "goalForm",
                          model: {
                            value: _vm.goalForm,
                            callback: function ($$v) {
                              _vm.goalForm = $$v
                            },
                            expression: "goalForm",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                            },
                            [
                              !_vm.updateGoalForm
                                ? _c("h5", { staticClass: "text-h6" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("goals.createGoal")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.updateGoalForm
                                ? _c("h5", { staticClass: "text-h6" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("goals.editGoal")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "black--text",
                                  attrs: { disabled: _vm.saving },
                                  on: { click: _vm.closeGoalDialog },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "pt-4 pb-4" },
                            [
                              [
                                _c("v-container", { staticClass: "pa-0" }, [
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "black--text mb-3 d-flex",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("createCampaign.chooseApp")
                                            ) + " "
                                          ),
                                          _c(
                                            "sup",
                                            {
                                              staticClass: "red--text top-star",
                                            },
                                            [_vm._v("*")]
                                          ),
                                        ]
                                      ),
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.appList,
                                          outlined: "",
                                          dense: "",
                                          "item-text": "appName",
                                          "item-value": "adamId",
                                          placeholder: _vm.$t(
                                            "createCampaign.selectApp"
                                          ),
                                          rules: _vm.appRules,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                [
                                                  _c("v-img", {
                                                    staticClass: "rounded-lg",
                                                    attrs: {
                                                      color: "secondary",
                                                      "lazy-src":
                                                        data.item.appIcon,
                                                      "max-width": "30",
                                                      "max-height": "30",
                                                      "min-width": "20",
                                                      "min-height": "20",
                                                      src: data.item.appIcon,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            data.item.appName
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.selectedApp,
                                          callback: function ($$v) {
                                            _vm.selectedApp = $$v
                                          },
                                          expression: "selectedApp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "black--text mb-3 d-flex",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("goals.chooseEvent"))
                                          ),
                                          _c(
                                            "sup",
                                            {
                                              staticClass: "red--text top-star",
                                            },
                                            [_vm._v("*")]
                                          ),
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "name",
                                          items: _vm.eventTypes,
                                          placeholder: _vm.$t(
                                            "goals.selectEventType"
                                          ),
                                          outlined: "",
                                          dense: "",
                                          "item-value": "name",
                                          rules: _vm.eventTypeRules,
                                          multiple: "",
                                        },
                                        model: {
                                          value: _vm.selectedEvent,
                                          callback: function ($$v) {
                                            _vm.selectedEvent = $$v
                                          },
                                          expression: "selectedEvent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "black--text mb-3 d-flex",
                                        },
                                        [
                                          _vm._v(
                                            "Limit Goal By Days Since Install"
                                          ),
                                          _c(
                                            "sup",
                                            {
                                              staticClass: "red--text top-star",
                                            },
                                            [_vm._v("*")]
                                          ),
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "text",
                                          items: _vm.daysSinceInstallTypes,
                                          placeholder: "Days Since Install",
                                          outlined: "",
                                          dense: "",
                                          "item-value": "value",
                                          rules: _vm.eventTypeRules,
                                        },
                                        model: {
                                          value: _vm.daysSinceInstall,
                                          callback: function ($$v) {
                                            _vm.daysSinceInstall = $$v
                                          },
                                          expression: "daysSinceInstall",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "black--text mb-3 d-flex",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("goals.goalName"))
                                          ),
                                          _c(
                                            "sup",
                                            {
                                              staticClass: "red--text top-star",
                                            },
                                            [_vm._v("*")]
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          rules: _vm.goalNameRules,
                                        },
                                        model: {
                                          value: _vm.goalName,
                                          callback: function ($$v) {
                                            _vm.goalName = $$v
                                          },
                                          expression: "goalName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                            ],
                            2
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end mt-0 pa-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "127",
                                    color: "grey",
                                    depressed: "",
                                    disabled: _vm.saving,
                                    outlined: "",
                                  },
                                  on: { click: _vm.closeGoalDialog },
                                },
                                [_vm._v(_vm._s(_vm.$t("goals.close")))]
                              ),
                              _vm.updateGoalForm
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        width: "127",
                                        color: "primary",
                                        depressed: "",
                                        loading: _vm.saving,
                                        disabled: _vm.saving || !_vm.goalForm,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateGoal()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("goals.update")))]
                                  )
                                : _vm._e(),
                              !_vm.updateGoalForm
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        width: "127",
                                        color: "primary",
                                        depressed: "",
                                        loading: _vm.saving,
                                        disabled: _vm.saving || !_vm.goalForm,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createGoal()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("goals.create")))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("DeleteGoal", {
            attrs: { deleteGoalMetadata: _vm.deleteGoalMetadata },
            on: {
              onDeleteGoal: function ($event) {
                return _vm.onUpdate()
              },
            },
          }),
        ],
        1
      ),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }