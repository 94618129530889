













































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DEL_GOAL } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    deleteGoalMetadata: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      goalId: null,
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    deleteGoalMetadata(data) {
      this.dialog = true;
      this.goalId = data.goalId;
    },
  },
  methods: {
   async delGoal() {
      this.deleting = true;
      await this.$apollo
        .query({
          query: DEL_GOAL,
          variables: {
            goalId: this.goalId
          },
        })
        .then((res) => {
          this.deleting = false;
        	this.dialog = false;
          if(res.data.delGoal.code == 0) {
						this.$emit('onDeleteGoal');
            this.$notify.success("Goal Deleted Successfully");
          }
          else if(res.data.delGoal.code == 7){
            this.$notify.error("Error while deleting goal");
          }
          else {
            this.$notify.error("Something went wrong");
          }
        })
        .catch((error) => {
					this.deleting = false;
          this.$notify.error("Something went wrong");
        });
    }
  },
});
